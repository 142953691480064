import * as React from "react"

import Home2 from "../components/dpcgc/Home2"

const IndexPage = () => (
  <>
    <Home2 />
  </>
)

export default IndexPage
